import type { CustomisedVoucherProduct } from '@voucher-shop/types/voucher-shop'
import type {
  CartVoucherProductInput,
  Cart,
  CartType,
  Cinema,
} from '#gql/default'
import { CART_STRINGS, CART_TYPE } from '@booking/constants/cart'

const {
  UPDATE_CART_ERROR,
  ADDED_TO_CART,
  UPDATED_IN_CART,
  DELETED_FROM_CART,
  ITEMS_ADDED_TO_CART,
  ITEMS_REMOVED_FROM_CART,
} = CART_STRINGS

interface AddToCartPayload {
  quantity: number
  selectedAmount: number
  voucherDesignId: string
  cartVoucherId?: string
  voucherProduct: CustomisedVoucherProduct
  locale: string
  onSuccess?: () => void
  onError?: () => void
}

export default async function useVoucherCart({ cinema }: { cinema?: Cinema }) {
  const { ct, fetchCinemaStrings } = useCinemaStrings()
  const { add: addMessage } = useMessage()
  const { cart, fetchCart, ensureCart, pending } = await useCart()

  async function getCartVoucher(cartVoucherId: string) {
    pending.value = true
    try {
      const cart = await fetchCart()
      return cart?.voucherProducts.find(
        ({ id }: { id: string }) => id === cartVoucherId
      )
    } finally {
      pending.value = false
    }
  }

  async function patchVoucherProducts(
    voucherProducts: CartVoucherProductInput[]
  ): Promise<Cart> {
    pending.value = true
    try {
      let cartId = cart.value?.id

      if (!cartId) {
        const { id } = await ensureCart({
          cinema,
          type: CART_TYPE.VOUCHER as CartType,
        })
        cartId = id
      }

      // Clean customisations before sending to API
      const cleanedVoucherProducts = voucherProducts.map((product) => ({
        ...product,
        customisations: product.customisations?.map((customisation) => {
          const cleaned: Record<string, any> = {}
          Object.entries(customisation).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
              cleaned[key] = value
            }
          })
          return cleaned
        }),
      }))

      const result = await GqlCartPatchVoucherProducts({
        cartId,
        voucherProducts: cleanedVoucherProducts,
      })

      const updatedCart = result.cartPatchVoucherProducts as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      throw new Error(error as string)
    } finally {
      pending.value = false
    }
  }

  function getCartUpdateMessage(
    newQuantity: number,
    cartVoucherId: string | undefined,
    productName: string,
    previousQuantity?: number
  ): string {
    // Handle deletion
    if (newQuantity === 0) {
      return ct(DELETED_FROM_CART, { item: productName })
    }

    // Handle quantity changes for existing items
    if (cartVoucherId && previousQuantity !== undefined) {
      const quantityDelta = newQuantity - previousQuantity

      // No quantity change, must be other updates (design, etc)
      if (quantityDelta === 0) {
        return ct(UPDATED_IN_CART, {
          formattedItem: formatQuantityAndName(newQuantity, productName),
        })
      }

      // Quantity increased
      if (quantityDelta > 0) {
        return ct(ITEMS_ADDED_TO_CART, {
          formattedItem: formatQuantityAndName(quantityDelta, productName),
        })
      }

      // Quantity decreased
      return ct(ITEMS_REMOVED_FROM_CART, {
        formattedItem: formatQuantityAndName(
          Math.abs(quantityDelta),
          productName
        ),
      })
    }

    // Handle new items
    return ct(ADDED_TO_CART, {
      formattedItem: formatQuantityAndName(newQuantity, productName),
    })
  }

  async function addToCart(payload: AddToCartPayload) {
    const {
      quantity,
      selectedAmount,
      voucherDesignId,
      cartVoucherId,
      voucherProduct,
      locale,
      onSuccess,
      onError,
    } = payload

    await fetchCinemaStrings({
      keys: [
        UPDATE_CART_ERROR,
        DELETED_FROM_CART,
        UPDATED_IN_CART,
        ADDED_TO_CART,
        ITEMS_ADDED_TO_CART,
        ITEMS_REMOVED_FROM_CART,
      ],
      cinemaId: cinema?.id,
    })

    try {
      // Get previous quantity if it's an update
      let previousQuantity
      if (cartVoucherId) {
        const existingItem = await getCartVoucher(cartVoucherId)
        previousQuantity = existingItem?.quantity
      }

      await patchVoucherProducts([
        {
          id: cartVoucherId,
          voucherProductId: voucherProduct.id,
          customisations: voucherProduct.customisations,
          voucherDesignId,
          locale,
          quantity,
          selectedAmount,
        },
      ])

      addMessage({
        message: getCartUpdateMessage(
          quantity,
          cartVoucherId,
          voucherProduct.name,
          previousQuantity
        ),
        severity: MESSAGE_SEVERITY.SUCCESS,
        type: MESSAGE_TYPE.TOAST,
      })
      onSuccess?.()
    } catch {
      addMessage({
        message: ct(UPDATE_CART_ERROR),
        severity: MESSAGE_SEVERITY.ERROR,
        type: MESSAGE_TYPE.TOAST,
      })
      onError?.()
    }
  }

  return {
    cart,
    patchVoucherProducts,
    getCartVoucher,
    addToCart,
    pending,
  }
}
