import revive_payload_client_kvNPDuBvzZ from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hI4PUHFhe6 from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DcEzLgFn5x from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YLlUnbOiMo from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aVPCw2zglK from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jDf6c7E3yW from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_IJoiaUg86G from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_dubu4xHRsx from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/apps/entradas/.nuxt/components.plugin.mjs";
import prefetch_client_uDTiSZP6MI from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/apps/entradas/.nuxt/formkitPlugin.mjs";
import plugin_q8uR6wNhkE from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.11_@types+node@22.10.7_jiti@2.4.2_ter_4i3hynduwmnc6uk5hc5hfuoli4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_lOU6X1yrGb from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@babel+core@7.26.0_@parcel+watcher@2.5.0_@types+node@22.10.7_graph_huah5bzahbhw6ukpim5kh6mfdu/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_AJWQwtokdQ from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.5_rollup@4.31.0_vue-router@4.4.3_vue@3.4.38_typescrip_sa3osev343hky64itgn6zsuipe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_0rgrA0KqAC from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.5_rollup@4.31.0_vue-router@4.4.3_vue@3.4.38_typescrip_sa3osev343hky64itgn6zsuipe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/layers/base/plugins/sentry.client.ts";
import google_publisher_tag_9jDGgApxI5 from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/layers/portal/plugins/google-publisher-tag.ts";
import widget_mode_E1yEbsrr9d from "/home/forge/deployments/entradas/live/2025-02-13_14-27-47_8c7373b/layers/portal/plugins/widget-mode.ts";
export default [
  revive_payload_client_kvNPDuBvzZ,
  unhead_hI4PUHFhe6,
  router_DcEzLgFn5x,
  payload_client_YLlUnbOiMo,
  navigation_repaint_client_aVPCw2zglK,
  check_outdated_build_client_jDf6c7E3yW,
  view_transitions_client_IJoiaUg86G,
  chunk_reload_client_dubu4xHRsx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uDTiSZP6MI,
  formkitPlugin_pZqjah0RUG,
  plugin_q8uR6wNhkE,
  plugin_lOU6X1yrGb,
  composition_AJWQwtokdQ,
  i18n_0rgrA0KqAC,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  google_publisher_tag_9jDGgApxI5,
  widget_mode_E1yEbsrr9d
]