export default defineNuxtPlugin({
  name: 'widget-mode',
  setup() {
    const widgetMode = useState('widgetMode', () => false)
    const route = useRoute()

    // Initialize widget mode based on route
    if (route.query.mode === 'widget') {
      setPageLayout('widget')
      useGqlHeaders({ 'x-kinoheld-context': 'widget' })
      widgetMode.value = true
    }

    // Watch for route changes in client
    if (import.meta.client) {
      watch(
        () => route.query.mode,
        (newMode, oldMode) => {
          if (oldMode === 'widget' && newMode !== 'widget') {
            navigateTo({
              ...route,
              query: {
                ...route.query,
                mode: 'widget',
                design: route.query.design,
              },
            })
          }
        }
      )
    }

    return {
      provide: {
        isWidgetMode: widgetMode,
      },
    }
  },
})
