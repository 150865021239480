<template>
  <ImageWrapper :image="imageObject" :config="imageConfig" :classes>
    <template #default="{ classList }">
      <ImagePlaceholder icon="kh:gallery-image" :class="[...classList]" />
    </template>
  </ImageWrapper>
</template>

<script setup lang="ts">
import type { RafflePrize } from '#gql/default'

interface Props {
  prize: RafflePrize
}

const { prize } = defineProps<Props>()

const classes = computed(() => ({
  placeholder: {
    'w-full h-full rounded-t-lg': true,
  },
  image: {
    'w-full h-auto': true,
  },
  container: {
    'aspect-[2/1] overflow-hidden rounded-t-lg relative': true,
  },
}))

const imageObject = getImage(prize, 'concession.thumbnail', prize.name)
const imageConfig = computed(() => useAppConfig().images.raffle.prize)

defineOptions({
  name: 'CmsBlockAdventCalendarPrizeImage',
})
</script>
