<script lang="ts" setup>
import useCmsCatalogConfig from '@cms/composables/use-cms-catalog-config'
import type { LinkParam } from '#gql/default'

export interface CmsBlockCinemasForMovieProps {
  id: string
  __typename: 'CmsCinemasForMovieBlock'
  movieId: string
  enablePagination?: boolean
  renderAboveTheFold: boolean
  showFallbackResults: boolean
  linkParams: LinkParam[]
  featureFlags?: string[]
  types?: string[]
  city?: string
  search?: string
  itemsPerPage: number
  filters: UrlFilter[]
  preserveFilters: boolean
}

const props = defineProps<CmsBlockCinemasForMovieProps>()
const { dataBlockId, dataBlockType, catalogProps } = useCmsCatalogConfig(props)

defineOptions({
  name: 'CmsBlockCinemasForMovie',
})
</script>

<template>
  <CatalogCinemasForMovie v-bind="catalogProps" :data-block-id :data-block-type :movie-id="movieId" />
</template>
